/* Custom */

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans EB";
  src: url("../fonts/OpenSans-ExtraBold.ttf") format("truetype");
}

html body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

div.banner-video { position: relative; }
div.banner-video video { pointer-events: none; width: 100%!important; /*max-height: 548px!important;*/ }

div.banner-links {
  position: absolute;
  text-align: center;;
  bottom: 10px;
  width: 100%;
}

div.banner-links .link-menu {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}

div.banner-links .link-menu:focus, div.banner-links .link-menu:hover {
  color: #f9fafb;
  background-color: #cccccc55;
  border-color: #fff;
}

/* Footer */

footer a {
  text-decoration: none;
  color: darkorange;
}

footer a:hover {
  color: white;
}

span.sc { font-size: 10px; }
span.made-with-love, p.tips { font-size: 10px; }

h1 { font-family: "Open Sans EB"; }

h2 {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 1.4rem!important;
}

/* Mint Zone */

div.connect-zone button { margin: 0 auto!important; }

button[data-testid="rk-connect-button"], button[data-testid="rk-account-button"] { border-radius: 0.375rem!important; }

div.mint-zone {
  text-align: center;
  color: white;
}

div.mint-zone .list-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

div.mint-zone .list-group-item {
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  div.mint-zone .list-group { flex-direction: column; }
  div.mint-zone .list-group-item { border-radius: 0!important; }
}

/* Collections */

div.card div.collection {
  text-align: center;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 10px;
}